import React from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import {
    WORDPRESS_URL,
    WP_POSTS_SLUG
} from "../../utils/constants";
import renderHTML from 'react-render-html';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";
import api from '../../Api';
import functions from '../../utils/functions';

class Contact extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            cta1: 'cta1-active',
            cta2: 'cta2',
            cta3: 'cta3',
            cta4: 'cta4',
            cta5: 'cta5',
            cta6: 'cta6',
            cta7: 'cta7',
            ctaActive: 'cta1',
            selectedContactCategory: 'GENERAL',
            bodyPost: {},
            isLoading: true,
            text: '',
            textError: "",
            firstname: '',
            firstnameError: "",
            lastname: '',
            lastnameError: "",
            phone: '',
            email: '',
            phoneError: "",
            captcha: '',
            captchaError: "",
            index: 1,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            leadership: "hidden",
            spokesperson: "hidden",
            vacancies: "hidden",
            accounting: "hidden",
            complaints: "hidden",
            questions: "hidden",
            reclamations: "hidden",
            support: "hidden",
            sale: "hidden",
            error: {},
            redirectInternalError: false,
            emailSendAlertClass: "alert-success",
            emailSendAlertText: "Váš dotaz byl úspěšně odeslán, děkujeme!",
            emailSendAlertDisplay: "d-none",
            emailSendButtonText: "Odeslat zprávu",
            emailSendButtonFa: "fa-chevron-right"
        };

        this.ctaToggle = this.ctaToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.bgToggle = this.bgToggle.bind(this);
        this.ContactToggle = this.ContactToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              headerBgClass: "home",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden"
          });
        } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                headerBgClass: "recultivation",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: ""
            });
        }
      }

      ContactToggle(section){
        this.setState({
            [section]: this.state[section] === "hidden" ? "" : "hidden"
        });
      }

    componentDidMount(){
        const PEOPLE_IN_COMPANY = "lide-ve-firme";

        axios.get( WORDPRESS_URL + WP_POSTS_SLUG + PEOPLE_IN_COMPANY)
        .then(res => {
            if (res.data[0] !== undefined) {
                this.setState({
                    isLoading: false, 
                    bodyPost: res.data[0]
                });                        
            } else {
                this.setState({
                    isLoading: false
                });

                this.props.history.push('/404');
            }            
        } )
        .catch( error => {
            console.log(error);  

            this.setState({ 
                error: error, 
                isLoading: false,
                redirectInternalError: true
            });       
        });

        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    ctaToggle = (ctaName) => {
        let cta1, cta2, cta3, cta4, cta5, cta6, cta7= '';
        let selectedContactCategory = this.state.selectedContactCategory;

        if(ctaName==='cta1'){
            cta1='cta1-active';
            selectedContactCategory = 'GENERAL';
        }
        else{
            cta1='cta1';
        }

        if(ctaName==='cta2'){
            cta2='cta2-active';            
            selectedContactCategory = "WORK";
        }
        else{
            cta2='cta2';
        }

        if(ctaName==='cta3'){
            cta3='cta3-active';
            selectedContactCategory = "BUSINESS";
        }
        else{
            cta3='cta3'
        }

        if(ctaName==='cta4'){
            cta4='cta4-active'
            selectedContactCategory = "MEDIA";
        }
        else{
            cta4='cta4'
        }

        if(ctaName==='cta5'){
            cta5='cta5-active'
            selectedContactCategory = "HQ";
        }
        else{
            cta5='cta5'
        }

        if(ctaName==='cta6'){
            cta6='cta6-active'
            selectedContactCategory = "COAL";
        }
        else{
            cta6='cta6'
        }

        if(ctaName==='cta7'){
            cta7='cta7-active'
            selectedContactCategory = "INVOICE";
        }
        else{
            cta7='cta7'
        }

        this.setState({
            cta1:cta1, 
            cta2:cta2, 
            cta3:cta3, 
            cta4:cta4, 
            cta5:cta5,
            cta6:cta6,
            cta7:cta7,
            ctaActive:ctaName, 
            selectedContactCategory: selectedContactCategory
        });
    }

    handleChange(event) {
        this.validateField(event.target.name, event.target.value);

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        const {firstname, lastname, phone, email, text, selectedContactCategory, captcha} = this.state;
        const recipients = functions.CategoryResponsibleEmails(selectedContactCategory);
        const companyWeb = "Severočeské doly a.s.";
        
        setTimeout(() => this.setState({ 
                emailSendAlertDisplay: 'd-none',  
                emailSendButtonText: "Odeslat zprávu",
                emailSendButtonFa: "fa-chevron-right"
            }), 10000
        );

        this.validateForm();

        if(!firstname || !lastname || !text || !phone || Number.isNaN(Number.parseInt(phone, 10)) || !captcha || captcha.toLowerCase() !== 'slunce') {
            event.preventDefault();
            return;
        }

        this.setState({
            emailSendButtonText: "Odesílám zprávu",
            emailSendButtonFa: "fa-spinner fa-spin"
        });

        let emailObject = {
            Recipients: recipients,
            Subject: "Zpráva z kontaktního formuláře na webu " + companyWeb,
            CompanyWeb: companyWeb,
            FirstName: firstname,
            LastName: lastname,
            Phone: Number.parseInt(phone, 10).toString(),
            Email: email != null ? email : "nezadán",
            EmailText: text
        }

        api.contact.send(emailObject).then((response)=> {
            if (response.data) {
                console.log(response.data);
                this.setState({
                    emailSendButtonText: "Zpráva odeslána",
                    emailSendButtonFa: "fa-check",
                    emailSendAlertDisplay: "d-block",
                    firstname: "",
                    lastname: "",
                    phone: "",
                    email: "",
                    text: "",
                    captcha: ""    
                });
            } else {
                this.setState({
                    emailSendButtonText: "Zpráva neodeslána",
                    emailSendButtonFa: "fa-times",
                    emailSendAlertDisplay: "d-block",                    
                    emailSendAlertClass: "alert-danger",
                    emailSendAlertText: "Při odeslání formuláře došlo k chybě, zkuste to prosím později",
                });
            }            
        }).catch(() => {
            this.setState({
                emailSendButtonText: "Zpráva neodeslána",
                emailSendButtonFa: "fa-times",
                emailSendAlertDisplay: "d-block",                    
                emailSendAlertClass: "alert-danger",
                emailSendAlertText: "Při odeslání formuláře došlo k chybě, zkuste to prosím později",
            });
        });
        
    }

    validateForm = () => {
        const { firstname, lastname, phone, text, captcha } = this.state;

        this.validateField("firstname", firstname);
        this.validateField("lastname", lastname);
        this.validateField("phone", phone);
        this.validateField("text", text);
        this.validateField("captcha", captcha);
    }

    validateField = (fieldName, value) => {
        switch(fieldName) {
            case 'firstname':      
                if(!value) {
                    this.setState({firstnameError: "contact-form-shadow-error"});
                } else {
                    this.setState({firstnameError: ""});
                }     

                break;
            case 'lastname':            
                if(!value) {
                    this.setState({lastnameError: "contact-form-shadow-error"});
                } else {
                    this.setState({lastnameError: ""});
                }    

                break;
            case 'phone':                        
                if(!value || Number.isNaN(Number.parseInt(value, 10))) {
                    this.setState({phoneError: "contact-form-shadow-error"});
                } else {
                    this.setState({phoneError: ""});
                }    

                break;
            case 'text':                  
                if(!value) {
                    this.setState({textError: "contact-form-shadow-error"});
                } else {
                    this.setState({textError: ""});
                }  

                break;
            case 'captcha':                  
                if(!value || value.toLowerCase() !== 'slunce') {
                    this.setState({captchaError: "contact-form-shadow-error"});
                } else {
                    this.setState({captchaError: ""});
                }  

                break;
          default:
            break;
        }
      }

    renderHeader = () => {
        return(
            <header className={"contact-header " + this.state.headerBgClass}>
                <Navbar/>
    
                <div className="sd-container">
                    <section id="sub-header">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center sub-header-sub-caption-visibility">
                                    <h6 className="sub-header-sub-caption text-uppercase">Zde najdete nejdůležitější</h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="sub-header-caption text-uppercase">Kontakty</h6>
                                </div> 
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="sub-header-chevrons">
                                    <img
                                        src={this.state.firstRectSrc}
                                        alt="chevron_white"
                                        className="first header-toogle-button"
                                        onClick={() => this.bgToggle(1)}
                                    />
                                    <img
                                        src={this.state.secondRectSrc}
                                        alt="chevron_sd"
                                        className="second header-toogle-button"
                                        onClick={() => this.bgToggle(2)}
                                    />
                                    </span>
                                </div>                      
                            </div>                        
                        </div>
    
                        <div className="row">
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">Adresa</h5>
                                <p>
                                    Severočeské doly a.s. <br />
                                    Boženy Němcové 5359 <br />
                                    Chomutov, 430 01
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">Fakturační údaje</h5>
                                <p>
                                    IČ: 49901982 <br />
                                    DIČ: CZ699005746
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">Datová schránka</h5>
                                <p>
                                    58gchbx
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">&nbsp;</h5>
                                <p>Společnost je zapsána v obchodním rejstříku vedeném Krajským soudem v Ústí nad Labem odd. B, vložka 495.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </header> 
        );
    }
    
    renderMainContainer = () => {
        const { emailSendAlertClass, emailSendAlertText, emailSendAlertDisplay, emailSendButtonText, emailSendButtonFa } = this.state;

        return(
            <section id="main-container" className="mb-5">
                <div className="sd-container">
                    <section id="contact-form">
                        <h1 className="text-white text-uppercase">Napište nám</h1>
    
                        <div className="contact-navigation-sm">
                            <div className="row contact-navigation mb-2">
                                <div className="col-6 text-center"><div className={this.state.cta1} onClick={()=>this.ctaToggle('cta1')}><span className="text-white text-uppercase contact-navigation-img-text">Obecné</span></div></div>
                                <div className="col-6 text-center"><div className={this.state.cta3} onClick={()=>this.ctaToggle('cta3')}><span className="text-white text-uppercase contact-navigation-img-text">Obchod</span></div></div>
                            </div>
            
                            <div className="row contact-navigation mb-2">
                                <div className="col-6 text-center"><div className={this.state.cta4} onClick={()=>this.ctaToggle('cta4')}><span className="text-white text-uppercase contact-navigation-img-text">Média</span></div></div>
                                <div className="col-6 text-center"><div className={this.state.cta5} onClick={()=>this.ctaToggle('cta5')}><span className="text-white text-uppercase contact-navigation-img-text">Ředitelství</span></div></div>
                            </div>

                            <div className="row contact-navigation">
                                <div className="col-6 text-center"><div className={this.state.cta6} onClick={()=>this.ctaToggle('cta6')}><span className="text-white text-uppercase contact-navigation-img-text">Prodej uhlí</span></div></div>
                                <div className="col-6 text-center"><div className={this.state.cta7} onClick={()=>this.ctaToggle('cta7')}><span className="text-white text-uppercase contact-navigation-img-text">Fakturace</span></div></div>
                            </div>
                        </div>
                        
                        <div className="contact-navigation-md">
                            <div className="row contact-navigation mb-2">
                                <div className="col-4 text-center"><div className={this.state.cta1} onClick={()=>this.ctaToggle('cta1')}><span className="text-white text-uppercase contact-navigation-img-text">Obecné</span></div></div>
                                <div className="col-4 text-center"><div className={this.state.cta3} onClick={()=>this.ctaToggle('cta3')}><span className="text-white text-uppercase contact-navigation-img-text">Obchod</span></div></div>
                                <div className="col-4 text-center"><div className={this.state.cta4} onClick={()=>this.ctaToggle('cta4')}><span className="text-white text-uppercase contact-navigation-img-text">Média</span></div></div>
                            </div> 

                            <div className="row contact-navigation mb-2">
                                <div className="col-4 text-center"><div className={this.state.cta5} onClick={()=>this.ctaToggle('cta5')}><span className="text-white text-uppercase contact-navigation-img-text">Ředitelství</span></div></div>
                                <div className="col-4 text-center"><div className={this.state.cta6} onClick={()=>this.ctaToggle('cta6')}><span className="text-white text-uppercase contact-navigation-img-text">Prodej uhlí</span></div></div>
                                <div className="col-4 text-center"><div className={this.state.cta7} onClick={()=>this.ctaToggle('cta7')}><span className="text-white text-uppercase contact-navigation-img-text">Fakturace</span></div></div>
                            </div>  
                        </div>
    
                        <div className="bg-white mt-4 contact-form-shadow">
                            <div className="p-3 w-100">
                                <div className={["alert no-radius", emailSendAlertClass, emailSendAlertDisplay].join(' ')} role="alert">
                                    {emailSendAlertText}
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="firstname" className="text-uppercase font-weight-bold">Jméno</label>
                                        <input type="text" name="firstname" value={this.state.firstname} onChange={this.handleChange} className={["form-control contact-form-input contact-form-shadow", this.state.firstnameError].join(' ')} id="firstname"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lastname" className="text-uppercase font-weight-bold">Příjmení</label>
                                        <input type="text" name="lastname" value={this.state.lastname} onChange={this.handleChange} className={["form-control contact-form-input contact-form-shadow", this.state.lastnameError].join(' ')} id="lastname"/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="phone" className="text-uppercase font-weight-bold">Telefon</label>
                                        <input type="text" name="phone" value={this.state.phone} onChange={this.handleChange} className={["form-control contact-form-input contact-form-shadow", this.state.phoneError].join(' ')} id="phone"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="text-uppercase font-weight-bold">Email</label>
                                        <input type="text" name="email" value={this.state.email} onChange={this.handleChange} className={["form-control contact-form-input contact-form-shadow"].join(' ')} id="email"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="text" className="text-uppercase font-weight-bold">Vaše zpráva</label>
                                    <textarea name="text" value={this.state.text} onChange={this.handleChange} className={["form-control radius-0 contact-form-shadow", this.state.textError].join(' ')} id="text" cols="30" rows="5"></textarea>                
                                </div>

                                <div className="form-group">
                                    <label htmlFor="text" className="text-uppercase font-weight-bold">Ochrana proti spamu</label>
                                    <input type="text" name="captcha" placeholder="Žlutá koule na obloze" value={this.state.captcha} onChange={this.handleChange} className={["form-control radius-0 contact-form-input contact-form-shadow", this.state.captchaError].join(' ')} id="captcha" />
                                </div>
                                    
                                <button type="submit" className="trapezoid text-uppercase sd-pointer" onClick={this.handleSubmit}>
                                    <span>
                                        {emailSendButtonText} <i className={["fa ml-2", emailSendButtonFa].join(' ')}></i>
                                    </span>
                                </button>                                
                            </div>
                        </div>
                    </section>
    
                    <section id="about" className="p-3">
                        <h1 className="text-uppercase">Lidé ve firmě</h1>
                    </section>
    
                    <section id="detail-contact" className="pb-3 pt-3 pl-3 pl-md-5 pr-3 pr-md-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="col-12 d-flex justify-content-between pl-0  pr-0  sd-pointer" onClick={() => this.ContactToggle("leadership")}>
                                    <h4 className="text-white text-uppercase pt-4 pb-2 sd-hidden-xs">Vedení společnosti</h4>
                                    <h4 className="text-white text-uppercase pt-4 pb-2 sd-visible-xs">Vedení</h4>
                                    <span className="pull-right text-uppercase pt-4 pb-4">
                                        <i className={this.state.leadership==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                    </span>
                                </div>
                                <div className={"pl-0 pr-0 pb-4 " + this.state.leadership}>
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Generální ředitel</b><br />Kancelář generálního ředitele</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 210</div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6"><b>Finanční ředitel</b><br />Sekretariát</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 230<br /><i className="fas fa-fax"></i> +420 474 604 241</div>
                                    </div>
                                    <br />
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Obchodní ředitel</b><br />Sekretariát</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 260</div>
                                    </div>
                                    <br />
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Technický  ředitel</b><br />Sekretariát</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 220</div>
                                    </div>
                                    <br />
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Personální  ředitel</b><br />Sekretariát</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 250<br /><i className="fas fa-fax"></i> +420 474 604 251</div>
                                    </div>
                                    <br />
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Výrobní  ředitel</b><br />Sekretariát</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 207</div>
                                    </div>
                                    <br />
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Ředitel komunikace a vnějších vztahů</b><br />Sekretariát</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 260</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between sd-pointer" onClick={() => this.ContactToggle("spokesperson")}>
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Tiskový mluvčí</h4>
                                    <span className="pull-right text-uppercase pt-4 pb-4">
                                        <i className={this.state.spokesperson==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                    </span>
                            </div>
                            <div className={"col-12 pl-0 pr-0 d-flex justify-content-between pb-4 " + this.state.spokesperson}>
                                <div className="col-sm-12 col-md-6 sd-hidden-xs"><b>Oddělení komunikace</b>
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position"><span className="sd-visible-xs"><b>Oddělení komunikace</b></span>Ing. Lukáš Kopecký, DiS.<br />+420 777 780 017</div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between align-items-center sd-pointer"  onClick={() => this.ContactToggle("vacancies")}> 
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Volná pracovní místa</h4>
                                    <span className="pull-right text-uppercase pt-4 pb-4">
                                        <i className={this.state.vacancies==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                    </span>
                            </div>

                            <div className={"col-12 pl-0 pr-0 d-flex justify-content-between pb-4 " + this.state.vacancies}>
                                <div className="col-sm-12 col-md-6  sd-hidden-xs"><b>Oddělení lidských zdrojů</b></div>
                                <div className="col-sm-6 col-md-6 detail-contact-text-position">
                                    <span className="sd-visible-xs"><b>Oddělení lidských zdrojů</b></span>
                                    <a className="link pull-right text-white" href="https://sdas.jobs.cz/" target='_blank'><span className="pull-right text-uppercase sd-hidden-xs">
                                        <span className="underline-first">Přejít</span> na stránku
                                            <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                        </span>
                                    </a>
                                    +420 417 805 056<br />+420 602 103 936<br />
                                </div>
                                <div className="col-sm-6 sd-visible-xs">
                                    <a className="link pull-right text-white" href="https://sdas.jobs.cz/" target='_blank'>
                                        <span className="pull-right text-uppercase sd-visible-xs text-right">
                                            <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="col-12 pl-0 pr-0 d-flex justify-content-between align-items-center sd-pointer"  onClick={() => this.ContactToggle("accounting")}> 
                                        <h4 className="text-white text-uppercase pt-4 pb-2">Mzdová účtárna</h4>
                                        <span className="pull-right text-uppercase pt-4 pb-4">
                                            <i className={this.state.accounting==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                        </span>
                                </div>

                                <div className={"pl-0 pr-0 pb-4 " + this.state.accounting}>
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Oddělení mzdového účetnictví</b><br />Doly Bílina</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position"><span className="sd-hidden-xs">&nbsp;</span>+420 417 804 062</div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">Správa a.s. a Doly Nástup Tušimice</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 417 804 064</div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between align-items-center pt-4 pb-4 sd-pointer">
                                <h4 className="text-white text-uppercase m-0">Sponzorství</h4>
    
                                <a className="link pull-right text-white" href="/sponzoring" target='_blank'><span className="pull-right text-uppercase sd-hidden-xs">
                                    <span className="underline-first">Přejít</span> na stránku
                                        <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                    </span>
        
                                    <span className="pull-right text-uppercase sd-visible-xs">
                                        <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between align-items-center pt-4 pb-4 sd-pointer">
                                <h4 className="text-white text-uppercase m-0">Březenský drak</h4>
    
                                <a className="link pull-right text-white" href="/clanek/brezensky-drak" target='_blank'><span className="pull-right text-uppercase sd-hidden-xs">
                                    <span className="underline-first">Přejít</span> na stránku
                                        <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                    </span>
        
                                    <span className="pull-right text-uppercase sd-visible-xs">
                                        <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12">
                                <div className="col-12 pl-0 pr-0 d-flex justify-content-between align-items-center sd-pointer"  onClick={() => this.ContactToggle("complaints")}> 
                                        <h4 className="text-white text-uppercase pt-4 pb-2">Fakturace</h4>
                                        <span className="pull-right text-uppercase pt-4 pb-4">
                                            <i className={this.state.complaints==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                        </span>
                                </div>

                                <div className={"pl-0 pr-0 pb-4 " + this.state.complaints}>
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6"><b>Odbor ekonomické podpory</b><br />Reklamace faktur</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position"><span className="sd-hidden-xs">&nbsp;</span>+420 417 805 068</div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">Dotazy k fakturám</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 604 296</div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between sd-pointer"  onClick={() => this.ContactToggle("reclamations")}>
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Reklamace uhlí</h4>
                                    <span className="pull-right text-uppercase pt-4 pb-4">
                                        <i className={this.state.reclamations==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                    </span>
                            </div>
                            <div className={"col-12 pl-0 pr-0 d-flex justify-content-between pb-4 " + this.state.reclamations}>
                                <div className="col-sm-12 col-md-6 sd-hidden-xs"><b>Oddělení prodeje</b>
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position"><span className="sd-visible-xs"><b>Oddělení prodeje</b></span>+420 417 804 246</div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between align-items-center sd-pointer"  onClick={() => this.ContactToggle("sale")}>                             
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Prodej uhlí</h4>
                                    <span className="pull-right text-uppercase pt-4 pb-4">
                                        <i className={this.state.sale==="hidden"?"fa ml-2 fa-chevron-down" : "fa ml-2 fa-chevron-up" }></i>
                                    </span>
                            </div>

                            <div className={"col-12 pl-0 pr-0 d-flex justify-content-between pb-4 " + this.state.sale}>
                                <div className="col-sm-12 col-md-6  sd-hidden-xs"><b>Oddělení prodeje</b></div>
                                <div className="col-sm-6 col-md-6 detail-contact-text-position">
                                    <span className="sd-visible-xs"><b>Oddělení prodeje</b></span>
                                    <a className="link pull-right text-white" href="https://www.sd-bilinskeuhli.cz/" target='_blank'><span className="pull-right text-uppercase sd-hidden-xs">
                                        <span className="underline-first">Přejít</span> na stránku
                                            <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                        </span>
                                    </a>
                                    +420 417 804 242<br />
                                </div>
                                <div className="col-sm-6 sd-visible-xs">
                                    <a className="link pull-right text-white" href="https://www.sd-bilinskeuhli.cz/" target='_blank'>
                                        <span className="pull-right text-uppercase sd-visible-xs text-right">
                                            <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
    
    renderSecondaryContainer = () => {
        return(
            <section id="secondary-container" className="sd-hidden-xs contact-secondary-container"></section>
        );
    }
    
    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }
    
    render(){
        const { isLoading, redirectInternalError, error } = this.state;
    
        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}
    
                        {this.renderMainContainer()}
    
                        {this.renderSecondaryContainer()}
    
                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Contact;