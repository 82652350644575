import React from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import renderHTML from 'react-render-html';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";
import functions from '../../utils/functions';
import api from '../../Api';
import setAuthorizationToken from '../../utils/setAuthorizationToken';

class Sponzoring extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            index: 1,

            OrganizaceICO:'',
            OrganizaceNazev:'',
            //legalForm: '',
            // legalForm:{key: 0,
            //     value: 0,
            //     PravniFormaId: 0,
            //     Code: '',
            //     PravniFormaNazev: '',
            //     label: '',
            //     name: 'legalForm'},
            PravniFormaId:'',
            PravniFormaNazev:'',
            legalForms:[],
            OrganizaceDIC:'',
            OrganizacePlatceDPH:false,
            OrganizaceRegMV:'',

            OrganizaceUlice:'',
            OrganizaceCP: '',
            OrganizaceCO:'',
            OrganizaceObec:'',
            PSC:0,

            OrganizaceBankovniUcet:'',
            OrganizaceBankaKod: '',

            ZastupceTitul:'',
            ZastupceJmeno:'',
            ZastupcePrijmeni: '',
            ZastupceTitulZa:'',
            ZastupceEmail:'',
            ZastupceTelefon:0,
            ZastupceMobil:0,

            KontaktTitul:'',
            KontaktJmeno:'',
            KontaktPrijmeni: '',
            KontaktTitulZa:'',
            KontaktEmail:'',
            KontaktTelefon:0,
            KontaktMobil:0,

            ProjektNazev:'',
            ProjektMistoRealizace:'',
            DatumOd:'',
            DatumDo:'',
            ProjektDatumZahajeni:'',
            ProjekDatumUkonceni:'',
            ProjektPopis:'',
            ProjektCastka:0,

            ZadostSoubor:null,
            ZadostSouborNazev: '',
            ZadostSouborData: '',
            RejstrikSoubor:null,
            RejstrikSouborNazev: '',
            RejstrikSouborData: '',
            VolbaSoubor:null,
            VolbaSouborNazev: '',
            VolbaSouborData: '',
            UcetSoubor:null,
            UcetSouborNazev: '',
            UcetSouborData: '',

            Soubor:null,
            SouborNazev: '',
            SouborData: '',

            ZapsalJmeno:'',
            ZapsalPrijmeni:'',
            ZapsalFunkce:'',
            ZapsalEmail:'',
            ZapsalMobil:'',


            SMSSection: 'hidden',
            SubmitButton: '',
            ZadostId:0,
            Kod:'',
            FormDisabled:false,
            error: {},
            redirectInternalError: false,
            
            requestDonateAlertClass: "alert-success",
            requestDonateAlertText: "Váše žádost byla úspěšně odeslána, děkujeme!",
            requestDonateAlertDisplay: "d-none",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSMSSubmit = this.handleSMSSubmit.bind(this);
        this.bgToggle = this.bgToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              headerBgClass: "home",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden"
          });
        } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                headerBgClass: "recultivation",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: ""
            });
        }
      }

    loadLegalForms(){
        api.sponzoring.pravniNormy().then((response) => {
            let legalForms = functions.PravniNormy(response);
            this.setState({
              legalForms: legalForms,
              isLoading: false
            })
          })
          .catch((error) => {
            console.log(error);

            this.setState({ 
                error: error, 
                isLoading: false,
                redirectInternalError: true
            });                    
          });
    }

    componentDidMount(){
        if (!functions.checkDataLocalStorage() || functions.checkTokenExpiry(localStorage.TOKEN_EXPIRES_IN)) {
            // Loguju se pomocí údajú a ktualizuji LS
            api.sponzoring.token().then(() => {
              // Nastavím auth. header
              setAuthorizationToken(localStorage.TOKEN);
      
              this.loadLegalForms();
            });
          } else {
            // Nastavím auth. header
            setAuthorizationToken(localStorage.TOKEN);
      
            this.loadLegalForms();
          }

        this.setState({
            isLoading: false
        });
        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }   

    handleChange(event) {
        if (event.target.type === "number") {
            if (event.target.name === "ProjektCastka" && event.target.value > 99999999) {
                alert("Byl překročen limit pro dotaci");
                return;
            } else if(event.target.name !== "ProjektCastka" && event.target.value.length > 9) {
                alert("Nesprávný formát čísla");
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.type === 'checkbox') {
            const { checked } = event.target;
              this.setState({
                OrganizacePlatceDPH: checked,
              });
        }
    }

    handleDropDownChange(event){
        const selectedIndex = event.target.options.selectedIndex;
        this.setState({
            [event.target.name]: event.target.value,
            PravniFormaId: event.target.options[selectedIndex].getAttribute('data-key'),
            PravniFormaNazev: event.target.value
        });

    }


    handleFileChange(event) {
        var reader = new FileReader();
        console.log(event.target.files[0]);
        let targetName = event.target.name;
        let targetFile = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                [targetName]: targetFile,
                [targetName + "Data"]: reader.result.substring(reader.result.indexOf(",")+1),
                [targetName + "Nazev"]: targetFile.name
              });
            console.log(reader.result); // vrací string začínající data:[typ souboru]; base64, [data]
          }
          reader.readAsDataURL(event.target.files[0]);
      }

    handleSubmit(event) {
            if (!functions.checkDataLocalStorage() || functions.checkTokenExpiry(localStorage.TOKEN_EXPIRES_IN)) {
                // Loguju se pomocí údajú a aktualizuji LS
                api.sponzoring.token().then(() => {
                  // Nastavím auth. header
                  setAuthorizationToken(localStorage.TOKEN);
          
                  this.SendForm();
                });
              } else {
                // Nastavím auth. header
                setAuthorizationToken(localStorage.TOKEN);
          
                this.SendForm();
              }

                this.toogleButtons();
                this.setState({
                    FormDisabled:true
                });
        event.preventDefault();
    }

    SendForm(){
        let form=this.state;

        if (!form.PravniFormaId) {
            alert("Vyberte právní formu");
            return;
        }

        let formData={
            PravniFormaId: form.PravniFormaId,
            PravniFormaNazev: form.PravniFormaNazev,
            OrganizacePlatceDPH: form.OrganizacePlatceDPH,
            OrganizaceDIC: form.OrganizaceDIC ? form.OrganizaceDIC : "",
            OrganizaceRegMV: form.OrganizaceRegMV ? form.OrganizaceRegMV : "",
            OrganizaceObecPSC: form.OrganizaceObecPSC,
            OrganizaceUlice: form.OrganizaceUlice ? form.OrganizaceUlice : "",
            OrganizaceCP: form.OrganizaceCP ? form.OrganizaceCP : "",
            OrganizaceCO: form.OrganizaceCO ? form.OrganizaceCO : "",
            OrganizaceBankaKod: form.OrganizaceBankaKod,
            OrganizaceBankovniUcet: form.OrganizaceBankovniUcet,
            ZastupceTitul: form.ZastupceTitul ? form.ZastupceTitul : "",
            ZastupceJmeno: form.ZastupceJmeno,
            ZastupcePrijmeni: form.ZastupcePrijmeni,
            ZastupceTitulZa: form.ZastupceTitulZa ? form.ZastupceTitulZa : "",
            ZastupceEmail: form.ZastupceEmail,
            ZastupceTelefon: form.ZastupceTelefon ? Number.parseInt(form.ZastupceTelefon, 10) : null,
            ZastupceMobil: Number.parseInt(form.ZastupceMobil, 10),
            KontaktTitul: form.KontaktTitul ? form.KontaktTitul : "",
            KontaktJmeno: form.KontaktJmeno ? form.KontaktJmeno : "",
            KontaktPrijmeni: form.KontaktPrijmeni ? form.KontaktPrijmeni : "",
            KontaktTitulZa: form.KontaktTitulZa ? form.KontaktTitulZa : "",
            KontaktEmail: form.KontaktEmail ? form.KontaktEmail : "",
            KontaktTelefon: form.KontaktTelefon ? Number.parseInt(form.KontaktTelefon, 10) : null,
            KontaktMobil: form.KontaktMobil ? Number.parseInt(form.KontaktMobil, 10) : null,
            ProjektPopis: form.ProjektPopis,
            ZadostSouborNazev: form.ZadostSouborNazev,
            ZadostSouborData: form.ZadostSouborData,
            RejstrikSouborNazev: form.RejstrikSouborNazev,
            RejstrikSouborData: form.RejstrikSouborData,
            VolbaSouborNazev: form.VolbaSouborNazev,
            VolbaSouborData: form.VolbaSouborData,
            UcetSouborNazev: form.UcetSouborNazev,
            UcetSouborData: form.UcetSouborData,
            SouborNazev: form.SouborNazev ? form.SouborNazev : "",
            SouborData: form.SouborData ? form.SouborData : "",
            ZapsalJmeno: form.ZapsalJmeno,
            ZapsalPrijmeni: form.ZapsalPrijmeni,
            ZapsalFunkce: form.ZapsalFunkce,
            ZapsalEmail: form.ZapsalEmail,
            ZapsalMobil: Number.parseInt(form.ZapsalMobil, 10),
            OrganizaceNazev: form.OrganizaceNazev,
            OrganizaceICO: form.OrganizaceICO,
            OrganizaceObec: form.OrganizaceObec,
            ProjektNazev: form.ProjektNazev,
            ProjektMistoRealizace: form.ProjektMistoRealizace,
            ProjektCastka: Number.parseInt(form.ProjektCastka, 10),
            ProjektDatumZahajeni: form.DatumOd ? new Date(new Date(form.DatumOd.toString()).toString().split('GMT')[0]+' UTC').toISOString() : null,
            ProjekDatumUkonceni: form.DatumDo ? new Date(new Date(form.DatumDo.toString()).toString().split('GMT')[0]+' UTC').toISOString() : null,
        }
        console.log(formData);

        api.sponzoring.CreateSponzorRequest(formData).then(
            (response)=>{
                console.log(response.data);
                if(response.data.Id){
                    this.setState({
                        ZadostId:response.data.Id,
                        FormDisabled:true
                    });
                    this.toogleButtons();
                }
            }
        ).catch((error) => {
            console.log(error);
            alert("Došlo k neočekávané chybě");
        });
    }

    toogleButtons(){
        this.setState({
            SMSSection: '',
            SubmitButton: 'hidden',
        });
    }

    handleSMSSubmit(event) {

        if (!functions.checkDataLocalStorage() || functions.checkTokenExpiry(localStorage.TOKEN_EXPIRES_IN)) {
            // Loguju se pomocí údajú a ktualizuji LS
            api.sponzoring.token().then(() => {
              // Nastavím auth. header
              setAuthorizationToken(localStorage.TOKEN);
      
              this.SendSMS();
            });
          } else {
            // Nastavím auth. header
            setAuthorizationToken(localStorage.TOKEN);
      
            this.SendSMS();
          }


        event.preventDefault();
    }

    SendSMS(){        
        let formData = {
            ZadostId: Number.parseInt(this.state.ZadostId, 10),
            Kod: this.state.Kod
        }

        setTimeout(() => this.setState({ 
                requestDonateAlertDisplay: 'd-none'
            }), 10000
        );

        api.sponzoring.ConfirmSMS(formData).then((response)=>{
            console.log(response);

            window.scrollTo(0, 0);

            if (response.data) {
                this.setState({
                    requestDonateAlertDisplay: "d-block",
                    SMSSection: 'hidden'
                });
            } else {
                this.setState({
                    requestDonateAlertDisplay: "d-block",
                    requestDonateAlertClass: "alert-danger",
                    requestDonateAlertText: "Při potvrzení žádosti došlo k chybě, prosím zkontrolujte zaslaný kód z SMS"
                });
            }
        }).catch((error) => {
            console.log(error);

            this.setState({
                requestDonateAlertDisplay: "d-block",
                requestDonateAlertClass: "alert-danger",
                requestDonateAlertText: "Při zpracování žádosti došlo k chybě, prosím zkuste to později."
            });
        });
    }

    renderHeader = () => {
        return(
            <header className={"sponzoring-header " + this.state.headerBgClass}>
                <Navbar/>
    
                <div className="sd-container">
                    <section id="sub-header">
                    <div className="row">
                        <div className="col-md-7 col-xl-8 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="sub-header-sub-caption text-uppercase">
                            Severočeské doly
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="sub-header-caption text-uppercase">
                            Žádost o 
                            <br />
                            sponzorský dar
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="sub-header-chevrons">
                            <img
                                src={this.state.firstRectSrc}
                                alt="chevron_white"
                                className="first header-toogle-button"
                                onClick={() => this.bgToggle(1)}
                            />
                            <img
                                src={this.state.secondRectSrc}
                                alt="chevron_sd"
                                className="second header-toogle-button"
                                onClick={() => this.bgToggle(2)}
                            />
                            </span>
                        </div>
                        </div>
                        <div className={"col-md-5 col-xl-4 " + this.state.firstArticle}>
                        <div className="d-flex justify-content-between align-items-center sd-mt-4">
                            <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                            Těžíme
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="sub-header-text">
                            V souladu s vizí efektivní a bezpečné těžby zajišťujeme
                            spolehlivé dodávky pro naše zákazníky, klademe důraz na
                            investice a inovativní přístup. Přitom věnujeme
                            mimořádnou pozornost bezpečnosti práce a ochraně zdraví.
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        <Link
                            to={"/tezime-kvalitni-uhli"}
                            className="who-we-are-more-btn-link"
                            >
                            <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                            <span className="btn-text text-white text-uppercase pl-4">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                            </Link>
                        </div>
                        </div>
    
                        <div className={"col-md-5 col-xl-4 " + this.state.secondArticle}>
                        <div className="d-flex justify-content-between align-items-center sd-mt-4">
                            <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                            Rekultivujeme
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="sub-header-text">
                            Měníme krajinu pro lepší život náš i budoucích generací tam, 
                            kde dříve probíhala povrchová těžba uhlí. 
                            Terén se rekultivacemi postupně mění na lokalitu s vodními díly a rozmanitou přírodou.
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        <Link
                            to={"/rekultivujeme"}
                            className="who-we-are-more-btn-link"
                            >
                            <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                            <span className="btn-text text-white text-uppercase pl-4">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                            </Link>
                        </div> 
                        </div>
                    </div>
                    </section>
                </div>
            </header> 
        );
    }
    
    renderMainContainer = () => {
        const { requestDonateAlertClass, requestDonateAlertDisplay, requestDonateAlertText } = this.state;
        let arrayOfData = this.state.legalForms;

        let options = arrayOfData.map((data) =>
            <option key={data.PravniFormaId} value={data.PravniFormaNazev} data-key={data.PravniFormaId} >
                {data.PravniFormaNazev}
            </option>
        );

        return(
            <section id="main-container" className="mb-5">
                <div className="sd-container">
                    <section id="sponzoring-form">
    
                        <div className="bg-white mt-4 contact-form-shadow">
                            

                            <form className="p-3 w-100" disabled onSubmit={this.handleSubmit}>
                                <div className={["alert no-radius", requestDonateAlertClass, requestDonateAlertDisplay].join(' ')} role="alert">
                                    {requestDonateAlertText}
                                </div>

                                <fieldset disabled={this.state.FormDisabled}>
                            <h1 className="text-uppercase">Informace o organizaci</h1>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="OrganizaceICO" className="text-uppercase font-weight-bold">IČ *</label>
                                        <input type="text" name="OrganizaceICO" value={this.state.OrganizaceICO} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceICO"/>
                                    </div>
                                    <div className="form-group col-md-8">
                                        <label htmlFor="OrganizaceNazev" className="text-uppercase font-weight-bold">Název *</label>
                                        <input type="text" name="OrganizaceNazev" value={this.state.OrganizaceNazev} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceNazev"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                    <label htmlFor="legalForm" className="text-uppercase font-weight-bold">Právní forma *</label>
                                        <select name="legalForm" defaultValue="" value={this.state.legalForm} onChange={this.handleDropDownChange} required className="form-control sponzoring-form-input contact-form-shadow">
                                            <option value="">-- Vyberte --</option>
                                            {options}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="OrganizaceDIC" className="text-uppercase font-weight-bold">DIČ</label>
                                        <input type="text" name="OrganizaceDIC" value={this.state.OrganizaceDIC} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceDIC"/>
                                    </div>
                                    <div className="form-group checkbox-form-group col-md-2">
                                        <label htmlFor="OrganizacePlatceDPH" className="text-uppercase font-weight-bold">Plátce DPH</label><br/>
                                        <select name="OrganizacePlatceDPH" value={this.state.OrganizacePlatceDPH} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow">
                                            <option value="false">Ne</option>
                                            <option value="true">Ano</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="OrganizaceRegMV" className="text-uppercase font-weight-bold">Registrační číslo ministertva vnitra</label>
                                        <input type="text" name="OrganizaceRegMV" value={this.state.OrganizaceRegMV} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceRegMV"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Sídlo organizace</h1>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="OrganizaceUlice" className="text-uppercase font-weight-bold">Název ulice</label>
                                        <input type="text" name="OrganizaceUlice" value={this.state.OrganizaceUlice} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceUlice"/>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="OrganizaceCP" className="text-uppercase font-weight-bold">č. popisné</label>
                                        <input type="text" name="OrganizaceCP" value={this.state.OrganizaceCP} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceCP"/>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="OrganizaceCO" className="text-uppercase font-weight-bold">č. orientační</label>
                                        <input type="text" name="OrganizaceCO" value={this.state.OrganizaceCO} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceCO"/>
                                    </div>
                                    <div className="form-group col-md-8">
                                        <label htmlFor="OrganizaceObec" className="text-uppercase font-weight-bold">Název obce *</label>
                                        <input type="text" name="OrganizaceObec" value={this.state.OrganizaceObec} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceObec"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="OrganizaceObecPSC" className="text-uppercase font-weight-bold">PSČ *</label><br/>
                                        <input type="number" name="OrganizaceObecPSC" value={this.state.OrganizaceObecPSC} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceObecPSC"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Bankovní údaje</h1>

                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label htmlFor="OrganizaceBankovniUcet" className="text-uppercase font-weight-bold">Číslo účtu *</label>
                                        <input type="text" name="OrganizaceBankovniUcet" value={this.state.OrganizaceBankovniUcet} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceBankovniUcet"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="OrganizaceBankaKod" className="text-uppercase font-weight-bold">Kód banky *</label>
                                        <input type="text" name="OrganizaceBankaKod" value={this.state.OrganizaceBankaKod} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="OrganizaceBankaKod"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Statutární zástupce</h1>

                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label htmlFor="ZastupceTitul" className="text-uppercase font-weight-bold">Titul před</label>
                                        <input type="text" name="ZastupceTitul" value={this.state.ZastupceTitul} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="ZastupceTitul"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="ZastupceJmeno" className="text-uppercase font-weight-bold">Jméno *</label>
                                        <input type="text" name="ZastupceJmeno" value={this.state.ZastupceJmeno} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZastupceJmeno"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="ZastupcePrijmeni" className="text-uppercase font-weight-bold">Příjmení *</label>
                                        <input type="text" name="ZastupcePrijmeni" value={this.state.ZastupcePrijmeni} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZastupcePrijmeni"/>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="ZastupceTitulZa" className="text-uppercase font-weight-bold">Titul za</label>
                                        <input type="text" name="ZastupceTitulZa" value={this.state.ZastupceTitulZa} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="ZastupceTitulZa"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ZastupceEmail" className="text-uppercase font-weight-bold">E-mail *</label><br/>
                                        <input type="email" name="ZastupceEmail" value={this.state.ZastupceEmail} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZastupceEmail"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ZastupceTelefon" className="text-uppercase font-weight-bold">Telefon</label>
                                        <input type="number" name="ZastupceTelefon" value={this.state.ZastupceTelefon} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="ZastupceTelefon"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ZastupceMobil" className="text-uppercase font-weight-bold">Mobil *</label><br/>
                                        <input type="number" name="ZastupceMobil" value={this.state.ZastupceMobil} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZastupceMobil"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Kontaktní osoba</h1>
                                <label className="text-uppercase "><i>(vyplňte pokud je jiná než statutární zástupce)</i></label>

                                <div className="form-row">
                                <div className="form-group col-md-2">
                                        <label htmlFor="KontaktTitul" className="text-uppercase font-weight-bold">Titul před</label>
                                        <input type="text" name="KontaktTitul" value={this.state.KontaktTitul} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktTitul"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="KontaktJmeno" className="text-uppercase font-weight-bold">Jméno</label>
                                        <input type="text" name="KontaktJmeno" value={this.state.KontaktJmeno} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktJmeno"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="KontaktPrijmeni" className="text-uppercase font-weight-bold">Příjmení</label>
                                        <input type="text" name="KontaktPrijmeni" value={this.state.KontaktPrijmeni} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktPrijmeni"/>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="KontaktTitulZa" className="text-uppercase font-weight-bold">Titul za</label>
                                        <input type="text" name="KontaktTitulZa" value={this.state.KontaktTitulZa} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktTitulZa"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="KontaktEmail" className="text-uppercase font-weight-bold">E-mail</label><br/>
                                        <input type="email" name="KontaktEmail" value={this.state.KontaktEmail} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktEmail"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="KontaktTelefon" className="text-uppercase font-weight-bold">Telefon</label>
                                        <input type="number" name="KontaktTelefon" value={this.state.KontaktTelefon} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktTelefon"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="KontaktMobil" className="text-uppercase font-weight-bold">Mobil</label><br/>
                                        <input type="number" name="KontaktMobil" value={this.state.KontaktMobil} onChange={this.handleChange} className="form-control sponzoring-form-input contact-form-shadow" id="KontaktMobil"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Informace o projektu</h1>
                                
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ProjektNazev" className="text-uppercase font-weight-bold">Název projektu *</label>
                                        <input type="text" name="ProjektNazev" value={this.state.ProjektNazev} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ProjektNazev"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ProjektMistoRealizace" className="text-uppercase font-weight-bold">Místo realizace *</label>
                                        <input type="text" name="ProjektMistoRealizace" value={this.state.ProjektMistoRealizace} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ProjektMistoRealizace"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="DatumOd" className="text-uppercase font-weight-bold">Datum zahájení *</label>
                                        <input type="date" name="DatumOd" value={this.state.DatumOd} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="DatumOd"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="DatumDo" className="text-uppercase font-weight-bold">Datum ukončení *</label>
                                        <input type="date" name="DatumDo" value={this.state.DatumDo} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="DatumDo"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ProjektPopis" className="text-uppercase font-weight-bold">Popis projektu *</label><br/>
                                        <textarea name="ProjektPopis" value={this.state.ProjektPopis} onChange={this.handleChange} cols="30" rows="5" required className="form-control contact-form-shadow" id="ProjektPopis"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ProjektCastka" className="text-uppercase font-weight-bold">Požadovaná částka Kč *</label>
                                        <input type="number" name="ProjektCastka" value={this.state.ProjektCastka} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ProjektCastka"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Povinné přílohy k žádosti</h1>
                                <label className="text-uppercase "><i>(scany dokumentů, maximální velikost povinných i ostatních příloh je dohromady 18MB)</i></label>

                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ZadostSoubor" className="text-uppercase font-weight-bold">Písemně podepsaná žádost statutárním orgánem *</label>
                                        <input type="file" name="ZadostSoubor" onChange={this.handleFileChange} required className="form-control sponzoring-form-file-input contact-form-shadow" id="ZadostSoubor"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="RejstrikSoubor" className="text-uppercase font-weight-bold">Výpis z obchodního rejstříku *</label>
                                        <input type="file" name="RejstrikSoubor" onChange={this.handleFileChange} required className="form-control sponzoring-form-file-input contact-form-shadow" id="RejstrikSoubor"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="VolbaSoubor" className="text-uppercase font-weight-bold">Podklad k volbě statutárního orgánu *</label>
                                        <input type="file" name="VolbaSoubor" onChange={this.handleFileChange} required className="form-control sponzoring-form-file-input contact-form-shadow" id="VolbaSoubor"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="UcetSoubor" className="text-uppercase font-weight-bold">Smlouva o zřízení a vedení běžného účtu *</label>
                                        <input type="file" name="UcetSoubor" onChange={this.handleFileChange} required className="form-control sponzoring-form-file-input contact-form-shadow" id="UcetSoubor"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Ostatní přílohy k žádosti</h1>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="Soubor" className="text-uppercase font-weight-bold">Soubor</label>
                                        <input type="file" name="Soubor" onChange={this.handleFileChange} className="form-control sponzoring-form-file-input contact-form-shadow" id="Soubor"/>
                                    </div>
                                </div>

                                <h1 className="text-uppercase">Zadavatel žádosti</h1>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ZapsalJmeno" className="text-uppercase font-weight-bold">Jméno *</label>
                                        <input type="text" name="ZapsalJmeno" value={this.state.ZapsalJmeno} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZapsalJmeno"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ZapsalPrijmeni" className="text-uppercase font-weight-bold">Příjmení *</label>
                                        <input type="text" name="ZapsalPrijmeni" value={this.state.ZapsalPrijmeni} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZapsalPrijmeni"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ZapsalFunkce" className="text-uppercase font-weight-bold">Funkce v žádající organizaci *</label><br/>
                                        <input type="text" name="ZapsalFunkce" value={this.state.ZapsalFunkce} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZapsalFunkce"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ZapsalEmail" className="text-uppercase font-weight-bold">E-mail *</label><br/>
                                        <input type="email" name="ZapsalEmail" value={this.state.ZapsalEmail} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZapsalEmail"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ZapsalMobil" className="text-uppercase font-weight-bold">Mobil *</label><br/>
                                        <input type="number" name="ZapsalMobil" value={this.state.ZapsalMobil} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="ZapsalMobil"/>
                                    </div>
                                </div>

                                <button type="submit" className={"trapezoid text-uppercase sd-pointer" + this.state.SubmitButton}><span>Vygenerovat SMS <i className="fa fa-chevron-right ml-2"></i></span></button>                                
                                </fieldset>
                            </form>
                            <form className={"pl-3 pr-3 pb-3 pt-0 w-100 " + this.state.SMSSection} onSubmit={this.handleSMSSubmit}>
                                <label htmlFor="ZapsalMobil" className="text-uppercase font-weight-bold">Zadejte ověřovací kód zaslaný prostřednictvím SMS</label><br/>
                                        
                                <div className="form-row">
                                    <div className="form-group col-md-6 col-sm-12 sms-col">
                                        <label htmlFor="Kod" className="text-uppercase font-weight-bold">Ověřovací kód z SMS *</label><br/>
                                        <input type="number" name="Kod" value={this.state.Kod} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="Kod"/>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12 sms-col">
                                        <button type="submit" className="trapezoid text-uppercase sd-pointer sms-button"><span>Potvrdit kód <i className="fa fa-chevron-right ml-2"></i></span></button>                                
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
    
    renderSecondaryContainer = () => {
        return(
            <div>
            <section className=" sponzoring-secondary-container" >
               <div>&nbsp;</div>
            </section> 
            <section id="secondary-container" className=" contact-secondary-container">
               
            </section>           
            </div>
        );
    }
    
    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }
    
    render(){
        const { isLoading, redirectInternalError, error } = this.state;
    
        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}
    
                        {this.renderMainContainer()}
    
                        {this.renderSecondaryContainer()}
    
                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Sponzoring;