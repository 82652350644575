const functions = {
    checkTokenExpiry : (tokenExpiresIn) => {
      return tokenExpiresIn < +Date.now();
    },
  
    cleanTokens: () => {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("TOKEN_EXPIRES_IN");
      localStorage.removeItem("TOKEN_REFRESH");
    },
  
    checkDataLocalStorage : () => {
      return (localStorage.TOKEN && localStorage.TOKEN_EXPIRES_IN && localStorage.TOKEN_REFRESH);
    },
  
    createTimestamExpiresIn : (expiresIn) => {
      let expireDate = new Date(Date.now() + (expiresIn * 1000));
  
      return +expireDate;
    },

    PravniNormy: function(response) {
        let dropdownData = response.data.map(function(data,idx){
            return {
              key: idx,
              value: data.Id,
              PravniFormaId: data.Id,
              Code: data.Kod,
              PravniFormaNazev: data.Nazev,
              label: data.Nazev,
              name: 'legalForm',
            };
        });
        
        return dropdownData;
      }, 

    CategoryResponsibleEmails: function(selectedContactCategory) {
      switch (selectedContactCategory) {
        case "GENERAL":
          return ["sdas@sdas.cz"];

        case "BUSINESS":
          return ["petraskova@sdas.cz"];
  
        case "MEDIA":
          return ["kopecky.lukas@sdas.cz"];

        case "HQ":
          return ["pasecka@sdas.cz"];

        case "COAL":
          return ["styndlova@sdas.cz"];
  
        case "INVOICE":
          return ["dundrova@sdas.cz"];

        default:
          return ["kopecky.lukas@sdas.cz"];
      }
    }
  }
  
  export default functions;