import React from 'react';
import axios from 'axios';
import Footer from '../Footer'
import renderHTML from 'react-render-html';
import Navbar from '../Navbar';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import defaultBg from '../../images/bg-new2.jpg';
import UpravnaUhli from '../../images/schema_uhli.png';

class CoalScheme extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: false, 
            error: {},
            redirectInternalError: false
        }
        this.RectHoverOver = this.RectHoverOver.bind(this);
        this.RectHoverOut = this.RectHoverOut.bind(this);
    }

    componentDidMount(){
       
    }

    RectHoverOver = (rect) =>{
        let element = document.getElementById(rect);
        console.log(element);
    }

    RectHoverOut = (rect) => {
        let element = document.getElementById(rect);
        console.log(element);
        // document.getElementById(rect).style.display = 'none';
    }

    renderHeader = () => {
        const { post } = this.state;

        return(
            <header className="detail-header h-100">
                <Navbar/>
        
        
                <div className="sd-container ">
                    <section id="sub-header" className="pb-5">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1 className="sub-header-caption text-uppercase detail-sub-header-caption  text-white">Cesta Bílinského uhlí Ledvickou úpravnou</h1>
                                </div>                       
                            </div>                        
                        </div>     
                        
                        <div className="row">
                            <div className="col-12 d-flex detail-read-more" onClick={()=>{window.scrollTo(0, 700);}}>
                                <h3 className="text-uppercase text-white"><bb>Čtěte</bb> dál</h3>
                                <i className="fa fa-chevron-down sd-text-primary ml-3 fa-2x"></i>
                            </div>
                        </div>
                    </section>
                </div>
            </header> 
        );
    }

    renderMainContainer = () => {

        return(
            <section id="main-container">
                <div className="sd-container">
        
                    <div className="detail-content detail-content-padding">
                        <div className="detail-title text-uppercase mb-4">
                            <h2>
                               
                            </h2>
                        </div>
        
                        <div className="detail-text">  
                            {this.renderImages()}
                        </div>
                    </div>
                    
                </div>
            </section>
        );
    }

    renderImages = () => {
        return(
            <div>
                <div id="upravna" className="image-coal" > 
                    <img src={UpravnaUhli}/>

                    <div className="rect-image-coal" style={{top:"15.05%", left:"34.24%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec1" className="rect-image-coal-hover">Tento provoz zabezpečuje prodej a nakládku tříděného uhlí na nákladní automobily.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"31.89%", left:"34.24%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec2" className="rect-image-coal-hover">Zde probíhá konečné rozdělení uhlí na jednotlivé tříděné druhy - kostka (velikost zrna 4-10 cm), ořech 1 (velikost zrna 2-4 cm), ořech 2 (velikost zrna 1-2,5 cm) a jejich nakládka do vagónů nebo nákladních aut.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"31.89%", left:"63.04%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec3" className="rect-image-coal-hover">Zde se nakládá uhlí na železniční vagóny pro dodání do elektráren a tepláren.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"49.74%", left:"21.36%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec4" className="rect-image-coal-hover">Těžené uhlí se rozděluje podle kvality tak, aby následné zpracování v úpravně uhlí umožnilo vyrábět výsledný produkt dle jakostních limitů. Uhlí se rozděluje na nízkopopelnaté a vysokopopelnaté. Z hlubinných zásobníků se uhlí dopravuje do hrubé drtírny.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"49.74%", left:"30.88%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec5" className="rect-image-coal-hover">Slouží pro zpracování uhlí s nežádoucí příměsí jílů. Ve speciálních nádobách s kapalinou dochází k oddělování jílů, které se usazují na dně a uhlí plovoucího u hladiny. Takto získaný meziprodukt se dále používá pro výrobu tříděných druhů uhlí.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"63.92%", left:"49.6%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec6" className="rect-image-coal-hover">Za pomoci kladivových drtičů je uhlí rozdrceno na velikost 0-4 cm, čímž se uhlí připraví pro konečné zpracování v elektrárnách.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"72.15%", left:"4.64%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec7" className="rect-image-coal-hover">Těžené uhlí se rozděluje podle kvality tak, aby následné zpracování v úpravně uhlí umožnilo vyrábět výsledný produkt dle jakostních limitů. Uhlí se rozděluje na nízkopopelnaté a vysokopopelnaté. Z hlubinných zásobníků se uhlí dopravuje do hrubé drtírny.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"72.15%", left:"21.28%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec8" className="rect-image-coal-hover">Drcením se uhlí zpracuje tak, aby mělo velikost zrn 0-10 cm</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"72.15%", left:"34.24%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec9" className="rect-image-coal-hover">V provozu hrubé třídírny se odtřídí velikosti zrn 0-1 cm, používané v energetice a teplárenství, a zbylé uhlí se rozdělí podle jeho dalšího zpracování. Nízkopopelnaté pro výrobu tříděného uhlí a vysokopopelnaté pro další úpravu a zpracování v elektrárnách.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"72.15%", left:"63.04%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec10" className="rect-image-coal-hover">Zde se míchají průmyslové směsi a hruboprach dle konkrétních požadavků elektráren a tepláren.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"72.15%", left:"74.72%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec11" className="rect-image-coal-hover">Dodává uhlí na pásové dopravníky Elektrárny Ledvice.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"86.58%", left:"63.04%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec12" className="rect-image-coal-hover">Je součástí směšovací stanice, která umožňuje výrobu aditivovaného paliva namícháním vápenného hydrátu do uhlí, tzv. hruboprachu. Při spalování takto upraveného druhu uhlí se docílí snížení obsahu síry v emisích a to bez nutnosti odsiření tepelných zdrojů.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"86.58%", left:"74.72%", width:"7.2%", height:"7.73%"}}>
                        <div id="rec13" className="rect-image-coal-hover">Slouží pro výdej hruboprachu o zrnitosti 0-1 cm na nakládku do kamionů.</div>
                    </div>
                    <div className="rect-image-coal" style={{top:"88.6%", left:"4.8%", width:"15.2%", height:"9.5%"}}>
                        <div id="rec14" className="rect-image-coal-hover"  style={{bottom:"105%", left:"-25%", width:"150%"}}>Od těžebních velkostrojů se uhlí o velikosti zrn 0-40 cm dopravuje soustavou dálkových pásových dopravníků na zauhlování úpravny uhlí.</div>
                    </div>
                </div>
            </div>
        );
    }

    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>         
        );
    }

    render(){
        const { isLoading, redirectInternalError, error } = this.state;

        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                    ) : (redirectInternalError ? (
                        <Redirect to={{
                            pathname: "/500",
                            state: { error: error }
                          }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}

                        {this.renderMainContainer()}

                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default CoalScheme;