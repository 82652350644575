import axios from 'axios';
import qs from 'querystring';
import { BASE_API_URL, TOKEN_URL, SDAS_API_URL } from './utils/constants';
import setAuthorizationToken from './utils/setAuthorizationToken';
import functions from './utils/functions';

const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, Authorization, X-Request-With"
  };

  const headers2 = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, X-Request-With"
  };

export default {
    sponzoring: {
        pravniNormy: () => axios
            .get(BASE_API_URL + '/partner/pravniformy'),

        IPAdress: () => axios
            .get('https://jsonip.com/', headers2),

            
        sponzoringReport: (CisloSap, IpAdresa) => 
        axios.post(`https://api.sd-portal.cz/SD.OKO.API/smlouvy/zaverecnazprava`,
          qs.stringify({
            CisloSap: CisloSap,
            IpAdresa: IpAdresa
          }),
          headers
      ),

        token: () => {
            return axios.post(TOKEN_URL,
                qs.stringify({
                grant_type: "password",
                username: "WEBSERVER",
                password: "BEAD5D00-5301-49F5-B459-00011703724D"
                }),
                headers
            ).then(res => {
                functions.cleanTokens();
        
                localStorage.setItem("TOKEN", res.data.access_token);
                localStorage.setItem("TOKEN_EXPIRES_IN", functions.createTimestamExpiresIn(res.data.expires_in));
                localStorage.setItem("TOKEN_REFRESH", res.data.refresh_token);
                
                setAuthorizationToken(res.data.access_token);
            });
        },

        CreateSponzorRequest: (formData) => {

            return axios
              .post(
                BASE_API_URL + "/internet/zadost2",
                formData,
                headers
              )
              .then(res => {
                return res;
              })
              .catch(err => {
                console.log(err);
              });
        },

        ConfirmSMS: (formData) => {

            return axios
              .post(
                BASE_API_URL + "/internet/zadost/sms",
                formData,
                headers
              )
              .then(res => {
                return res;
              })
              .catch(err => {
                console.log(err);
              });
        },
    },

    contact: {
      send: (emailObject) => {
        return axios.post(
          SDAS_API_URL + "/utils/emails/send/info", 
          qs.stringify(emailObject),
          {
            headers: { 
            "Content-Type": "application/x-www-form-urlencoded"
          }}
        ).then(res => {
          return res;
        }).catch(err => {
          console.log(err);
        });
      }
    }
}


  