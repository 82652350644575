import React from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import renderHTML from 'react-render-html';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";
import functions from '../../utils/functions';
import api from '../../Api';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import deleteAuthorizationToken from '../../utils/deleteAuthorizationToken';

class SponzoringReport extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            index: 1,

            CisloSap:'',
            
            
            SubmitButton: '',
        
            FormDisabled:false,
            error: {},
            redirectInternalError: false,
            
            
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.bgToggle = this.bgToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              headerBgClass: "home",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden"
          });
        } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                headerBgClass: "recultivation",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: ""
            });
        }
      }



    componentDidMount(){
        if (!functions.checkDataLocalStorage() || functions.checkTokenExpiry(localStorage.TOKEN_EXPIRES_IN)) {
            // Loguju se pomocí údajú a ktualizuji LS
            api.sponzoring.token().then(() => {
              // Nastavím auth. header
              setAuthorizationToken(localStorage.TOKEN);
            });
          } else {
            // Nastavím auth. header
            setAuthorizationToken(localStorage.TOKEN);
          }

        this.setState({
            isLoading: false
        });
        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }   

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });

    }



    handleSubmit(event) {
        deleteAuthorizationToken(localStorage.TOKEN);   
        this.SendForm();

        if (!functions.checkDataLocalStorage() || functions.checkTokenExpiry(localStorage.TOKEN_EXPIRES_IN)) {
            // Loguju se pomocí údajú a ktualizuji LS
            api.sponzoring.token().then(() => {
              // Nastavím auth. header
              setAuthorizationToken(localStorage.TOKEN);
            });
          } else {
            // Nastavím auth. header
            setAuthorizationToken(localStorage.TOKEN);
          }

        event.preventDefault();
    }

    SendForm(){

        

        api.sponzoring.IPAdress().then(
            (response)=>{
                if(response.data.ip){
                    let IPAdress = response.data.ip;
                    if (!functions.checkDataLocalStorage() || functions.checkTokenExpiry(localStorage.TOKEN_EXPIRES_IN)) {
                        // Loguju se pomocí údajú a ktualizuji LS
                        api.sponzoring.token().then(() => {
                          // Nastavím auth. header
                          setAuthorizationToken(localStorage.TOKEN);
                        });
                      } else {
                        // Nastavím auth. header
                        setAuthorizationToken(localStorage.TOKEN);
                      }

                    api.sponzoring.sponzoringReport(this.state.CisloSap, IPAdress).then(
                        (response2)=>{
                            console.log(response2.data);
                            if(response2.data.Data){
                                const base64 = response2.data.Data;
                                const link = document.createElement('a');
                                link.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + base64;
                                link.download = 'Závěrečná_hodnotící_zpráva.docx';
                                link.click();
                            }
                        }
                    );
                }
            }
        ).catch((error) => {
            console.log(error);
            alert("Došlo k neočekávané chybě");
        });

        
    }

    renderHeader = () => {
        return(
            <header className={"sponzoring-header-report " + this.state.headerBgClass}>
                <Navbar/>
    
                <div className="sd-container">
                    <section id="sub-header">
                    <div className="row">
                        <div className="col-md-7 col-xl-8 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="sub-header-sub-caption text-uppercase">
                            Severočeské doly
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="sub-header-caption text-uppercase">
                            Závěrečná
                            <br />
                            hodnotící zpráva
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="sub-header-chevrons">
                            <img
                                src={this.state.firstRectSrc}
                                alt="chevron_white"
                                className="first header-toogle-button"
                                onClick={() => this.bgToggle(1)}
                            />
                            <img
                                src={this.state.secondRectSrc}
                                alt="chevron_sd"
                                className="second header-toogle-button"
                                onClick={() => this.bgToggle(2)}
                            />
                            </span>
                        </div>
                        </div>
                        <div className={"col-md-5 col-xl-4 " + this.state.firstArticle}>
                        <div className="d-flex justify-content-between align-items-center sd-mt-4">
                            <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                            Těžíme
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="sub-header-text">
                            V souladu s vizí efektivní a bezpečné těžby zajišťujeme
                            spolehlivé dodávky pro naše zákazníky, klademe důraz na
                            investice a inovativní přístup. Přitom věnujeme
                            mimořádnou pozornost bezpečnosti práce a ochraně zdraví.
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        <Link
                            to={"/tezime-kvalitni-uhli"}
                            className="who-we-are-more-btn-link"
                            >
                            <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                            <span className="btn-text text-white text-uppercase pl-4">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                            </Link>
                        </div>
                        </div>
    
                        <div className={"col-md-5 col-xl-4 " + this.state.secondArticle}>
                        <div className="d-flex justify-content-between align-items-center sd-mt-4">
                            <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                            Rekultivujeme
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="sub-header-text">
                            Měníme krajinu pro lepší život náš i budoucích generací tam, 
                            kde dříve probíhala povrchová těžba uhlí. 
                            Terén se rekultivacemi postupně mění na lokalitu s vodními díly a rozmanitou přírodou.
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        <Link
                            to={"/rekultivujeme"}
                            className="who-we-are-more-btn-link"
                            >
                            <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                            <span className="btn-text text-white text-uppercase pl-4">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                            </Link>
                        </div> 
                        </div>
                    </div>
                    </section>
                </div>
            </header> 
        );
    }
    
    renderMainContainer = () => {
        const { requestDonateAlertClass, requestDonateAlertDisplay, requestDonateAlertText } = this.state;

        return(
            <section id="main-container" className="mb-5">
                <div className="sd-container">
                    <section id="sponzoring-form-report">
    
                        <div className="bg-white mt-4 contact-form-shadow">
                            

                            <form className="p-3 w-100" disabled onSubmit={this.handleSubmit}>
                                <div className={["alert no-radius", requestDonateAlertClass, requestDonateAlertDisplay].join(' ')} role="alert">
                                    {requestDonateAlertText}
                                </div>

                                <fieldset disabled={this.state.FormDisabled}>
                            <h1 className="text-uppercase">Generovat závěrečnou hodnotící zprávu dle ID</h1>
                                <div className="form-row">
                                    <div className="form-group col-md-7">
                                        <input type="text" name="CisloSap" value={this.state.CisloSap} onChange={this.handleChange} required className="form-control sponzoring-form-input contact-form-shadow" id="CisloSap"/>
                                    </div>
                                    <div className="form-group col-md-5">
                                <button type="submit" className={"trapezoid text-uppercase sd-pointer" + this.state.SubmitButton}><span>Generovat <i className="fa fa-chevron-right ml-2"></i></span></button>                                
                                </div>
                                </div>
                                </fieldset>
                            </form>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
    
    renderSecondaryContainer = () => {
        return(
            <div>
            <section className=" sponzoring-report-secondary-container" >
               <div>&nbsp;</div>
            </section> 
            <section id="secondary-container" className=" contact-secondary-container">
               
            </section>           
            </div>
        );
    }
    
    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }
    
    render(){
        const { isLoading, redirectInternalError, error } = this.state;
    
        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}
    
                        {this.renderMainContainer()}
    
                        {this.renderSecondaryContainer()}
    
                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default SponzoringReport;