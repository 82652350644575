import React from 'react';
import Homepage from './components/pages/Homepage';
import About from './components/pages/About';
import Buy from './components/pages/Buy';
import Contact from './components/pages/Contact';
import Detail from "./components/pages/posts/Detail";
import Aktuality from './components/pages/Aktuality';
import NotFound from './components/pages/NotFound';
import Section from './components/pages/posts/Section';
import TileFirst from './components/pages/TileFirst';
import TileSecond from './components/pages/TileSecond';
import TileThird from './components/pages/TileThird';
import TileFourth from './components/pages/TileFourth';
import SearchResults from './components/pages/SearchResults';
import Sponzoring from './components/pages/Sponzoring';
import SponzoringReport from './components/pages/SponzoringReport';
import InternalServerError from './components/pages/InternalServerError';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Helping from './components/pages/Helping';
import ProjectsEU from './components/pages/ProjectsEU';
import CoalScheme from './components/pages/CoalScheme';
import Law from './components/pages/Law';
import MiningLists from './components/pages/MiningLists';

export const routeCodes = {
  HOME: '/',  
  HELPING: '/clanek/pomahame-obcim',
  DETAILPOST: '/clanek/:slug',
  AKTUALITY: '/aktuality',
  CONTACT: '/kontakty',
  ABOUT: '/o-spolecnosti',
  BUY: '/nakup',
  LAW: '/svobodny-pristup-k-informacim',
  MININGLISTS: '/hornicke-listy',
  SECTION: '/odpovedna-firma',
  TILE1: '/tezime-kvalitni-uhli', 
  TILE2: '/rekultivujeme',
  TILE3: '/pomahame',
  TILE4: '/chranime-prirodu',
  SEARCHRESULTS: '/hledat/:search',
  SPONZORING: '/sponzoring',
  SPONZORINGREPORT: '/sponzoring/zaverecna-hodnotici-zprava',
  PROJECTS: '/projekty-spolufinancovane-eu',
  COALSCHEME: '/cesta_bilinskeho_uhli_ledvickou_upravnou',
  JOBS: '/stipendijni-program'
  };

class App extends React.Component {
	render() {
		return (
      <BrowserRouter basename="">
        <Switch>
          <Route path={ routeCodes.HOME } component={Homepage} exact />          
          <Route path={ routeCodes.HELPING } component={Helping} exact />
          <Route path={ routeCodes.DETAILPOST } component={Detail} exact />
          <Route path={ routeCodes.AKTUALITY } component={Aktuality} exact />
          <Route path={ routeCodes.CONTACT } component={Contact} exact />
          <Route path={ routeCodes.ABOUT } component={About} exact />
          <Route path={ routeCodes.BUY } component={Buy} exact />
          <Route path={ routeCodes.LAW } component={Law} exact />
          <Route path={ routeCodes.MININGLISTS } component={MiningLists} exact />
          <Route path={ routeCodes.SECTION } component={Section} exact />
          <Route path={ routeCodes.TILE1 } component={TileFirst} exact />
          <Route path={ routeCodes.TILE2 } component={TileSecond} exact />
          <Route path={ routeCodes.TILE3 } component={TileThird} exact />
          <Route path={ routeCodes.TILE4 } component={TileFourth} exact />
          <Route path={ routeCodes.SEARCHRESULTS } component={SearchResults} exact />
          <Route path={ routeCodes.SPONZORING } component={Sponzoring} exact />
          <Route path={ routeCodes.SPONZORINGREPORT } component={SponzoringReport} exact />
          <Route path={ routeCodes.PROJECTS } component={ProjectsEU} exact />
          <Route path={ routeCodes.COALSCHEME } component={CoalScheme} exact />
          <Route path={ routeCodes.JOBS } component={() => {
                window.location.href = 'https://sdas.jobs.cz/';
                return null;
            }}/>
          <Route path="/500" component={InternalServerError} />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
		);
	}
}

export default App;
