import React, { Component } from 'react';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import Navbar from '../Navbar';
import errorImage from '../../images/error.png';

export default class InternalServerError extends Component {
   componentDidMount(){
        if(this.props.location.state){
            let error = { message: this.props.location.state.error.message, stack: this.props.location.state.error.stack };
            console.log(error);
        }
    }

    renderHeader = () => {
        return(
            <header className="detail-header h-100">
                <Navbar/>
        
                <div className="sd-container ">
                    <section id="sub-header" className="pb-5">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1 className="sub-header-caption text-uppercase notFound-sub-header-caption  text-white">500!</h1>
                                </div>                       
                            </div>                        
                        </div>       
                    </section>
                </div>
            </header> 
        );
    }

    renderMainContainer = () => {
        return(
            <section id="main-container">
                <div className="sd-container">
                    <div className="detail-content detail-content-padding">
                        <div id='error'>
                            <img src={errorImage} className="m-3 error-image" alt="Error" />
                            <h1 className="notFoundTitle">Chyba aplikace</h1>
                            <p className="notFoundDesc">
                                    Omlouváme se. Při zpracování požadavku stránky došlo k chybě. Zkuste to prosím později.
                                    <br/>
                                    <br/>
                                    K pokračování prohlížení webu použijte navigaci nebo se vraťte na úvodní stránku.
                            </p>

                            <div className="d-flex justify-content-between align-items-center">
                                <Link
                                to={'/'}
                                className="home-btn-link"
                                >
                                    <div className="home-btn text-white d-flex align-items-center">
                                        <span className="btn-text text-uppercase">
                                        Hlavní stránka{" "}
                                        <i className="fa fa-chevron-right ml-2"></i>
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>                    
                </div>
            </section>
        );
    }

    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>         
        );
    }

    render() {
        return (
            <section id="web">
                <div className="wrapper">
                    {this.renderHeader()}

                    {this.renderMainContainer()}

                    {this.renderFooter()}
                </div>
            </section>        
        );
    }
}