import React from 'react';
import Footer from '../Footer'
import Navbar from '../Navbar';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import BilinaIMG from '../../images/Bilina.png';
import TusimiceIMG from '../../images/Tusimice.png';
import { WORDPRESS_URL } from '../../utils/constants';

class Helping extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: false, 
            error: {},
            redirectInternalError: false
        }
    }

    componentDidMount(){
       
    }

    renderHeader = () => {
        const { post } = this.state;

        return(
            <header className="detail-header h-100">
                <Navbar/>
        
        
                <div className="sd-container ">
                    <section id="sub-header" className="pb-5">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1 className="sub-header-caption text-uppercase detail-sub-header-caption  text-white">Projekt „POMÁHÁME“​</h1>
                                </div>                       
                            </div>                        
                        </div>     
                        
                        <div className="row">
                            <div className="col-12 d-flex detail-read-more" onClick={()=>{window.scrollTo(0, 700);}}>
                                <h3 className="text-uppercase text-white"><bb>Čtěte</bb> dál</h3>
                                <i className="fa fa-chevron-down sd-text-primary ml-3 fa-2x"></i>
                            </div>
                        </div>
                    </section>
                </div>
            </header> 
        );
    }

    renderMainContainer = () => {

        return(
            <section id="main-container">
                <div className="sd-container">
        
                    <div className="detail-content detail-content-padding">
                        <div className="detail-title text-uppercase mb-4">
                            <h2>
                            Pomáháme zejména obcím, se kterými sousedí doly Bílina a Tušimice.​
                            </h2>
                        </div>
        
                        <div className="detail-text">  
                            {this.renderImages()}

                            <figure class="wp-block-gallery columns-3 is-cropped mt-5">
                                <ul class="blocks-gallery-grid">
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <a href={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715320715_Darování-mechanizace-pro-město-Lom_2.jpg"}>
                                                <img src={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715320715_Darování-mechanizace-pro-město-Lom_2.jpg"} alt="" data-id="513" data-full-url={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715320715_Darování-mechanizace-pro-město-Lom_2.jpg"} data-link={WORDPRESS_URL + "/715320715_darovani-mechanizace-pro-mesto-lom_2/"} class="wp-image-513" />
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <a href={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715766702_Rekonstrukce-sřechy-kláštera-v-Mariánských-Radčicích_2.jpg"}>
                                                <img src={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715766702_Rekonstrukce-sřechy-kláštera-v-Mariánských-Radčicích_2.jpg"} alt="" data-id="514" data-full-url={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715766702_Rekonstrukce-sřechy-kláštera-v-Mariánských-Radčicích_2.jpg"} data-link={WORDPRESS_URL + "/715766702_rekonstrukce-srechy-klastera-v-marianskych-radcicich_2/"} class="wp-image-514" />
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <a href={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715881805_Víceúčelové-sportovní-hřiště-Braňany._2.jpg"}>
                                                <img src={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715881805_Víceúčelové-sportovní-hřiště-Braňany._2.jpg"} alt="" data-id="515" data-full-url={WORDPRESS_URL + "/admin/wp-content/uploads/2020/03/715881805_Víceúčelové-sportovní-hřiště-Braňany._2.jpg"} data-link={WORDPRESS_URL + "/715881805_viceucelove-sportovni-hriste-branany-_2/"} class="wp-image-515" />
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <a href={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86477250_17-4-2014-089.jpg"}>
                                                <img src={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86477250_17-4-2014-089.jpg"} alt="" data-id="48" data-full-url={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86477250_17-4-2014-089.jpg"} data-link={WORDPRESS_URL + "/86477250_17-4-2014-089/"} class="wp-image-48" srcset={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86477250_17-4-2014-089.jpg 900w, " + WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86477250_17-4-2014-089-300x200.jpg 300w, " + WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86477250_17-4-2014-089-768x512.jpg 768w"} sizes="(max-width: 900px) 100vw, 900px" />
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <a href={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86571000_branany.jpg"}>
                                                <img src={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86571000_branany.jpg"} alt="" data-id="49" data-full-url={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86571000_branany.jpg"} data-link={WORDPRESS_URL + "/86571000_branany/"} class="wp-image-49" srcset={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86571000_branany.jpg 900w, " + WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86571000_branany-300x200.jpg 300w, " + WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86571000_branany-768x512.jpg 768w"} sizes="(max-width: 900px) 100vw, 900px" />
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <a href={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86727250_img_8163.jpg"}>
                                                <img src={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86727250_img_8163.jpg"} alt="" data-id="52" data-full-url={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86727250_img_8163.jpg"} data-link={WORDPRESS_URL + "/86727250_img_8163/"} class="wp-image-52" srcset={WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86727250_img_8163.jpg 900w, " + WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86727250_img_8163-300x200.jpg 300w, " + WORDPRESS_URL + "/admin/wp-content/uploads/2019/12/86727250_img_8163-768x512.jpg 768w"} sizes="(max-width: 900px) 100vw, 900px" />
                                            </a>
                                        </figure>
                                    </li>
                                </ul>
                            </figure>
                        </div>
                    </div>
                    
                </div>
            </section>
        );
    }

    renderImages = () => {
        return(
            <div>
                <h1 style={{marginTop:"20px", marginBottom:"20px"}}>Důl Bílina</h1>
                <div id="bilina" className="image-map"> 
                    <img src={BilinaIMG}/>
                    <a href="https://www.mesto-lom.cz/" target="_blank"><div id="Lom" className="towns-image-map" style={{top:"11%", left:"16%", width:"15.4%", height:"33.2%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.osek.cz/" target="_blank"><div id="Osek" className="towns-image-map" style={{top:"6%", left:"38%", width:"20%", height:"14%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.duchcov.cz/" target="_blank"><div id="Duchcov" className="towns-image-map" style={{top:"15%", left:"60.5%", width:"26%", height:"24%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.marianskeradcice.cz/" target="_blank"><div id="MR" className="towns-image-map" style={{top:"48%", left:"0", width:"28%", height:"10%", borderRadius:"7px"}}></div></a>
                    <a href="http://www.ledvice.cz/" target="_blank"><div id="Ledvice" className="towns-image-map" style={{top:"44%", left:"72%", width:"17%", height:"10%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.obec-svetec.cz/w/obec-chotejovice/" target="_blank"><div id="Chotejovice" className="towns-image-map" style={{top:"54%", left:"82%", width:"15%", height:"10%", borderRadius:"7px"}}></div></a>
                    <a href="http://www.branany.cz/" target="_blank"><div id="Branany" className="towns-image-map" style={{top:"80%", left:"26%", width:"18.5%", height:"11%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.bilina.cz/" target="_blank"><div id="Bilina" className="towns-image-map" style={{top:"70%", left:"61%", width:"30.5%", height:"26%", borderRadius:"7px"}}></div></a>
                </div>

                <h1 style={{marginTop:"20px", marginBottom:"20px"}}>Důl Tušimice</h1>
                <div id="tusimice" className="image-map"> 
                    <img src={TusimiceIMG}/>
                    <a href="http://www.obec-misto.cz/" target="_blank"><div id="Misto" className="towns-image-map" style={{top:"7%", left:"2%", width:"10%", height:"15%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.malkov.cz/" target="_blank"><div id="Malkov" className="towns-image-map" style={{top:"15%", left:"32%", width:"9%", height:"11%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.cernovice-ulk.cz/" target="_blank"><div id="Cernovice" className="towns-image-map" style={{top:"6%", left:"45%", width:"16%", height:"21%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.obecsporice.cz/" target="_blank"><div id="Sporice" className="towns-image-map" style={{top:"14%", left:"62%", width:"13%", height:"18%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.drouzkovice.cz/" target="_blank"><div id="Drouzkovice" className="towns-image-map" style={{top:"29%", left:"79%", width:"20%", height:"15%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.mesto-kadan.cz/" target="_blank"><div id="Kadan" className="towns-image-map" style={{top:"61%", left:"4%", width:"20%", height:"35%", borderRadius:"7px"}}></div></a>
                    <a href="https://www.obecbrezno.cz/" target="_blank"><div id="Brezno" className="towns-image-map" style={{top:"69%", left:"71%", width:"13%", height:"16%", borderRadius:"7px"}}></div></a>
                </div>
            </div>
        );
    }

    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>         
        );
    }

    render(){
        const { isLoading, redirectInternalError, error } = this.state;

        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                    ) : (redirectInternalError ? (
                        <Redirect to={{
                            pathname: "/500",
                            state: { error: error }
                          }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}

                        {this.renderMainContainer()}

                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Helping;